import { useEffect } from 'react';
import './App.css';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};


function App() {
  useEffect(() => {
    //redirect to pdf file
    window.location.href = "سكينة وإحياء.pdf";
  }, []);
  return (
    <div className="myContainer">
      <Document file="sakina-and-ehya.pdf" options={options}>
        <Page pageNumber={1} />
      </Document>
    </div>
  );
}

export default App;
